import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import DateFromToFilter from './items/dateFromToFilter';
import ContractStatusFilter from './items/GenericRadiosFilter';
import StaffFilter from '../ui/filters/items/StaffFilter';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import EventTypeFilter from '../ui/filters/items/EventTypeFilter';
import CrmFieldsFilter from './CrmFieldsFilter';
import MultiSelect from '../ui/filters/items/MultiSelect';

const EventsFilterDrawer = ({
  open,
  setOpen,
  blockList = [],
  isLoading = false,
}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [hasErrorObjType, setHasErrorObjType] = useState(false);
  const [searchParams] = useSearchParams();
  const [isEventAvailable, setIsEventAvailable] = useState(false);
  const [isStaffSelected, setIsStaffSelected] = useState(false);

  function findInputType(queryParams) {
    const multiTypes = ['CHANGE_STATUS', 'CONNECT_STATUS'];
    const singleTypes = ['LEAD_STAFF_CONNECT', 'LEAD_STAFF_CHANGE'];

    for (const key in queryParams) {
      const value = queryParams[key];

      if (multiTypes.includes(value)) {
        return 'multi';
      }

      if (singleTypes.includes(value)) {
        return 'single';
      }
    }

    return null;
  }

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    const isMultiType = findInputType(params) === 'multi';
    const singleType = findInputType(params) === 'single';

    setIsStaffSelected(singleType);
    setIsEventAvailable(isMultiType);
  }, [searchParams]);

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  const { data: objTypes, isLoading: isObjTypeLoading } = useQuery({
    queryKey: 'admin/event/types',
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/event/types');
      return response?.data?.data;
    },
    enabled: !hasErrorObjType,
    onError: () => {
      setHasErrorObjType(true);
    },
    retry: false,
  });

  const options = useMemo(
    () =>
      objTypes?.map((opt) => ({
        code: opt?.id,
        label: JSON.parse(opt?.name)?.[i18n.language],
      })),
    [objTypes, i18n.language]
  );

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            {t('common.button.clear')}
          </Button>
        </div>
        <DateFromToFilter
          disabled={isLoading}
          label={t('common.fields.date')}
          labels={{ start: t('common.fields.date'), end: '' }}
        />
        <ContractStatusFilter
          options={options || []}
          disabled={isObjTypeLoading || hasErrorObjType}
          queryName='object_type'
          label={t('common.filter.object_type')}
        />
        <DateFromToFilter
          disabled={isLoading}
          label={t('common.filter.object_created_at')}
          queries={['createdFrom', 'createdTill']}
          labels={{ start: t('common.fields.date'), end: '' }}
        />
        <EventTypeFilter />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='user_id'
        />
        <StaffFilter
          disabled={isLoading}
          label={t('common.filter.staff_for_lead')}
          query='user_id2'
        />
        <>
          {!isStaffSelected ? (
            <>
              <MultiSelect
                objectType='previous'
                queryKeys={{ mainQuery: 'previous', childQuery: 'pipes' }}
                title={t('common.filter.prv_value')}
                size='large'
                isEventAvailable={isEventAvailable}
                blockList={isEventAvailable ? blockList : []}
              />
              <MultiSelect
                queryKeys={{ mainQuery: 'next', childQuery: 'pipes2' }}
                title={t('common.filter.next_value')}
                size='large'
                isEventAvailable={isEventAvailable}
                blockList={isEventAvailable ? blockList : []}
              />
            </>
          ) : (
            <>
              <StaffFilter
                disabled={isLoading}
                label={'common.filter.prv_value'}
                query='old_user_id'
              />
              <StaffFilter
                disabled={isLoading}
                label={'common.filter.next_value'}
                query='next_user_id'
              />
            </>
          )}
        </>

        <CrmFieldsFilter />
      </div>
    </Drawer>
  );
};
export default EventsFilterDrawer;
