import { Button, IconButton } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import REPAIR_TYPE from 'shared/repairTypeList';
import getLabelByTypeList from 'utils/getLabelByTypeList';
import { NoImageIcon } from 'customIcons';
import ImagePreviewDialog from 'components/ui/dialogs/ImagePreviewDialog';

import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

export default function HomesSwiper({ homes }) {
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState({});
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0);

  return (
    <div className='component-add-edit-body h-[calc(100%-14px)]'>
      <div className='base-border relative bg-white h-full rounded-[15px] p-4 my-3'>
        <div className='text-[18px] leading-[25px] mb-5'>
          {t('order.modal.add.title')}
        </div>
        {homes?.length > 0 && (
          <Swiper
            lazy='true'
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Pagination, Navigation]}
            className='[&_.swiper-pagination-bullet-active]:w-5 [&_.swiper-pagination-bullet-active]:rounded-md [&_.swiper-pagination-bullet]:transition-all h-[calc(100%-45px)]'
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={(a) => {
              setSliderActiveIndex(a?.activeIndex);
            }}
          >
            {homes?.map(({ home }) => (
              <SwiperSlide key={home?.id}>
                <div className='base-data w-full h-[271px] flex gap-1 p-2 pb-10 px-3 rounded-md'>
                  <div className='home-data-item flex flex-col'>
                    <span className='data-item-value'>
                      <Button
                        type='button'
                        // variant='contained'
                        className='!-mb-[10px]'
                        fullWidth
                        disabled={!(home?.plan && home?.plan?.link)}
                        onClick={() => {
                          setOpen(true);
                          setPlan(home?.plan);
                        }}
                      >
                        {home?.plan && home?.plan?.link ? (
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/${home?.plan?.link}`}
                            className='!h-[233px] !w-full object-contain'
                            alt=''
                          />
                        ) : (
                          <NoImageIcon />
                        )}
                      </Button>
                    </span>
                  </div>
                  <div className='home-data flex flex-col justify-between grow'>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.objectName')}:
                      </span>
                      <span className='data-item-value'>
                        {home?.blocks?.objects?.name}
                      </span>
                    </div>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.blockName')}:
                      </span>
                      <span className='data-item-value text-base'>
                        {home?.blocks?.name}
                      </span>
                    </div>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.homeNumber')}:
                      </span>
                      <span className='data-item-value'>{home?.number}</span>
                    </div>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.stage')}:
                      </span>
                      <span className='data-item-value'>{home?.stage}</span>
                    </div>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.rooms')}:
                      </span>
                      <span className='data-item-value'>{home?.rooms}</span>
                    </div>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.area')}:
                      </span>
                      <span className='data-item-value'>
                        {home?.square}{' '}
                        <Trans i18nKey='common.global.meter'>
                          m<sup>2</sup>
                        </Trans>
                      </span>
                    </div>
                    <div className='home-data-item flex gap-2 items-center rounded-md p-1 text-[14px] leading-[16px]'>
                      <span className='data-item-title font-medium text-[#979797]'>
                        {t('contract.view.homeDetail.isrepaired')}:
                      </span>
                      <span className='data-item-value'>
                        {getLabelByTypeList(REPAIR_TYPE, home?.isrepaired)}
                      </span>
                    </div>
                  </div>
                </div>
                {/* </div>
              </div> */}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {homes?.length > 1 && (
          <Fragment>
            <div
              className={`swiper-navigation-button-wrapper absolute top-1/2 left-2 prev${
                sliderActiveIndex == 0 ? ' is-disabled' : ''
              }`}
            >
              <IconButton
                variant='swiperNavigationButton'
                color='primary'
                onClick={() => {
                  swiperRef.current?.slidePrev();
                }}
                disabled={sliderActiveIndex == 0}
                size='small'
              >
                <i className='bi bi-chevron-left' />
              </IconButton>
            </div>
            <div
              className={`swiper-navigation-button-wrapper absolute top-1/2 right-2 next${
                sliderActiveIndex == homes?.length - 1 ? ' is-disabled' : ''
              }`}
            >
              <IconButton
                variant='swiperNavigationButton'
                color='primary'
                onClick={() => {
                  swiperRef.current?.slideNext();
                }}
                disabled={sliderActiveIndex == homes?.length - 1}
                size='small'
              >
                <i className='bi bi-chevron-right' />
              </IconButton>
            </div>
          </Fragment>
        )}
        {open && (
          <ImagePreviewDialog open={open} setOpen={setOpen} url={plan?.link} />
        )}
      </div>
    </div>
  );
}
