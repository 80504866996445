import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import BaseTable from 'components/ui/tables/BaseTable';
import AccounterFilter from 'components/ui/filters/items/AccounterFilter';
import useTopPanel from 'hooks/useTopPanel';
import { paymentTypeVariants } from 'shared/tableColVariantsList';
import DailyPayments from './DailyPayments';
import PaymentFilterDrawer from 'components/dashboard/PaymentFilterDrawer';
import ObjectFilter from 'components/ui/filters/items/ObjectFilter';
import SearchInputNavbar from 'components/SearchInputNavbar';
import useMenu from 'hooks/useMenu';
const Payment = () => {
  const { hasChild } = useMenu();

  const [openFilter, setOpenFilter] = useState(false);
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const view = (code) => hasChild('PAYMENTS', code);
  const onClickRow = (row) => {
    navigate(`/admin/contract/view/${row?.contract_id}`);
  };

  useEffect(() => {
    setComponent(
      <div className='component-title  flex gap-4 whitespace-nowrap items-center'>
        {t('payment.title')}
        <ObjectFilter />
      </div>
    );
  }, [i18n.language]);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center'>
            {view('BANK') && (
              <Link className='no-underline mr-2' to='/admin/payment/bank'>
                <Button
                  variant='base'
                  disable={`${refetch}`}
                  startIcon={<i className='bi bi-bank' />}
                >
                  {t('bank.title')}
                </Button>
              </Link>
            )}
            <Button
              variant='base'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setOpenFilter((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
          </div>
          <div className='grow flex items-center gap-2'>
            <SearchInputNavbar inputKey='name' fullWidth />
            <div className='w-96'>
              <AccounterFilter className='!h-[45px] bg-white [&>fieldset]:border-gray-200' />
            </div>
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Link
              to={`${process.env.REACT_APP_BACKEND_URL}/excel/payments`}
              className='no-underline !ml-2'
            >
              <Button variant='base'>
                <i className='bi bi-download' />
              </Button>
            </Link>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => navigate('/admin/payment/add')}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <DailyPayments isRefetch={refetch} />

      <div className='component-table-wrapper'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/payments'
          emitTableColumns={{ open, setOpen }}
          tableName='payments'
          headCells={[
            { code: 'paymentId', label: t('payment.paymentId') },
            { code: 'number', label: '№' },
            { code: 'fullname', label: t('common.table.custom') },
            { code: 'contract', label: t('common.table.contractNumber') },
            { code: 'date', label: t('common.table.date') },
            { code: 'sum', label: t('common.table.paymentAmount') },
            { code: 'type_id', label: t('common.table.typeId') },
            { code: 'staff', label: t('common.table.staff') },
          ]}
          columns={[
            { code: 'id' },
            { code: 'number' },
            { code: 'fullname' },
            { code: 'contract', type: 'nested', childStr: 'name' },
            { code: 'date', type: 'date' },
            { code: 'sum', type: 'priceCurrency' },
            {
              code: 'type_id',
              type: 'customStatus',
              variants: paymentTypeVariants,
            },
            { code: 'staff', type: 'nested', childStr: 'name' },
          ]}
          actionGetCheque={true}
          onClickRow={onClickRow}
        />
      </div>
      <PaymentFilterDrawer open={openFilter} setOpen={setOpenFilter} />
    </div>
  );
};

export default memo(Payment);
