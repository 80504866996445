import React, { memo, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export default memo(function RejectTypeModal({
  open,
  setOpen,
  checkedList,
  formik,
}) {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { values, setFieldValue } = formik;

  const handleClose = () => {
    setOpen(false);
    setFieldValue('reject_id', null);
  };
  const onCombine = () => {
    formik?.handleSubmit?.();
    setOpen(false);
  };
  const onChange = (value) => {
    setFieldValue('reject_id', value);
  };

  const { isLoading, isFetching, data } = useQuery({
    queryKey: ['/dictionary/rejectreasons'],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/dictionary/rejectreasons`);
      return response.data.data;
    },
    onSuccess: (res) => {
      const data = res?.lead;
      if (data) {
      }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{
        sx: { minWidth: 720, boxShadow: 'none' },
        className: '!base-border',
      }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='order-view-dialog-title' className='!bg-gray-100'>
        <div className='text-xl text-center'>Rad etish sababi</div>
      </DialogTitle>

      <DialogContent>
        <div className='my-2 min-w-[500px]'>
          <div className='crm-lead-search-wrapper'>
            {isLoading || isFetching ? (
              <div className='flex items-center justify-center my-[14px]'>
                <CircularProgress size={20} color='inherit' />
              </div>
            ) : (
              data &&
              data.length > 0 && (
                <FormControl className='!w-full'>
                  <RadioGroup
                    aria-labelledby='repair-type-radio-buttons-group'
                    name='repair-type-radio-buttons-group-name'
                    value={values?.reject_id}
                    onChange={(event) => onChange(event.target.value)}
                  >
                    {data.map((item) => (
                      <div className='mb-1 w-full' key={item?.id}>
                        <FormControlLabel
                          className='w-full !border-0'
                          value={item?.id}
                          control={<Radio />}
                          label={t(item?.name)}
                          type='radio'
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              )
            )}
          </div>
        </div>
        <div className='mb-4 mt-6'>
          <Divider />
        </div>
        <div className='flex justify-end items-center gap-2'>
          <Button variant='outlined' color='error' onClick={handleClose}>
            {t('common.button.cancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onCombine}
            disabled={!values?.reject_id}
          >
            {t('common.button.save')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});
